import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function App(){
  const LandingPage = lazy(() => import("./pages/landingPage/default"));
  const ExpiredPage = lazy(() => import("./pages/expiredPage/default"));
  const NotFound = lazy(() => import("./pages/404/default"));
  const MobileBlankLayout = lazy(() => import("./layouts/mobileBlankLayout/default"))
  return (
    <>
      <Router>
        <Suspense>
          <Routes>
            <Route element={<MobileBlankLayout/>}>
                <Route path="/" element={<LandingPage/>} exact/>
                <Route path="/expired-offer" element={<ExpiredPage/>} exact/>
            </Route>
            <Route path="*" element={<NotFound/>} exact/>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}